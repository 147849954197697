import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useCallback, useState } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { SettingsContext } from './settings-context';
import {useDispatch, useSelector} from "../../../redux/store";
import {getShopSettings} from "../../../redux/slices/shops";




// ----------------------------------------------------------------------

const STORAGE_KEY = 'settings';
const tg = window.Telegram?.WebApp;

// Telegram.WebApp.onEvent(themeChanged, function(){});

export function SettingsProvider({ children, defaultSettings }) {

  const { state, update, reset } = useLocalStorage(STORAGE_KEY, defaultSettings);
  const settings = useSelector((reduxState) => reduxState.shops.settings.settings);
  const {curShopId} = useSelector((reduxState) => reduxState.startform);


  const [openDrawer, setOpenDrawer] = useState(false);

  const isArabic = localStorageGetItem('i18nextLng') === 'ar';

  const dispatch = useDispatch();

  useEffect(() => {
      // console.log("default settings" , curShopId);
      if(curShopId){
          dispatch(getShopSettings(curShopId));
      }

  } , [curShopId, dispatch])

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);

  // Direction by lang
  const onChangeDirectionByLang = useCallback(
    (lang) => {
      update('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
    },
    [update]
  );

  // Direction by color themeColorPresets
    const onChangeColorPresets = useCallback((themeColorPresets) => {
        update('themeColorPresets' , themeColorPresets )
    } , [update])

  const onChangeThemeMode = useCallback(
    (themeMode) => {
      update('themeMode', themeMode === 'dark' ? 'dark' : 'light');
    },
    [update]
  );
  /**
   * Смена темы в зависимости от темы телеграм tg.colorScheme
   */
  // const themeTg = 'dark';
  const themeTg = tg?.colorScheme;


  useEffect(() => {
    onChangeThemeMode(themeTg);

  }, [onChangeThemeMode, themeTg]);

  useEffect(() => {
      // console.log("settings" , settings);
      // console.log("defaultSettings.themeColorPresets" , defaultSettings.themeColorPresets);
      const setClrsPres = settings?.color || 'default' ;
      // console.log("setClrsPres" , setClrsPres);
      onChangeColorPresets(setClrsPres)
      // if(defaultSettings.themeColorPresets !== setClrsPres){
      //    //  onChangeColorPresets(settings?.color||'default')
      //     onChangeColorPresets(setClrsPres)
      // }
  } , [defaultSettings.themeColorPresets, onChangeColorPresets, settings])

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(state, defaultSettings);

  const onShopId = useCallback(
      (shopid) => {
        // console.log("UPDATE" , update);
        update('shopid', shopid);
      },
      [update]
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      // Direction
      onChangeDirectionByLang,
      // Reset
      canReset,
      onReset: reset,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      onShopId ,
      tgWebApp: tg ,
    }),
    [reset, update, state, canReset, openDrawer, onCloseDrawer, onToggleDrawer, onChangeDirectionByLang, onShopId]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
  defaultSettings: PropTypes.object,
};
