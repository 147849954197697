import {lazy, Suspense} from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
// components
import { SplashScreen } from 'src/components/loading-screen';
import {AuthGuard} from "../../auth/guard";
import GuestGuard from "../../auth/guard/guest-guard";
// ----------------------------------------------------------------------
// для навигации и определения referrer


export const ShopPage = lazy(() => import('src/pages/product/list'));
export const MainAppPage = lazy(() => import('src/pages/general-app-page'));

const Page404 = lazy(() => import('src/pages/404'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
const ProductFastOrderPage = lazy(() => import('src/pages/product/fastorder'));
const ProductCheckoutPage = lazy(() => import('src/pages/product/checkout'));
const ProductArticleDetailPage = lazy(()=>import('src/pages/product/article'));
// LANDING
const LandingMainPage = lazy(() => import('src/pages/landing/main_lp'));
// EVENT
const EventMainPage = lazy(() => import('src/pages/enent/main_event'));
// PAYS
const PaysMainPage = lazy(() => import('src/pages/pays/list'));
const AgreePage = lazy(() => import('src/pages/agreement-view'));
const PolicyPage = lazy(() => import('src/pages/policy-view'));

// GAMES
const GamesMainPage = lazy(() => import('src/pages/games/leaderboard'));
const GamesRefPage = lazy(() => import('src/pages/games/referral-link'));


// QUIZ
const QuizDetailPage = lazy(() => import('src/pages/quiz/details'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
  <AuthGuard userType='subscriber' >
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
  </AuthGuard>
    ),
    children: [
        { path: '404', element: <Page404 /> } ,
        { path: 'shop' ,
            children: [
                { element: <ProductListPage />, index: true },
                { path: ':shopid', element: <ProductListPage /> },
            ]
        },

        { path: 'pays' ,
            children: [
                {element: <PaysMainPage /> , index: true } ,
                {path: 'agreement' , element: <AgreePage />} ,
                {path: 'policy' , element: <PolicyPage />} ,
            ]} ,


        {
            path: 'lp' ,
            children: [
                {element: <LandingMainPage /> , index: true } ,
                {path: ':shopid' , element: <LandingMainPage />} ,
            ]
        },
        {
            path: 'event' ,
            children: [
                {element: <EventMainPage /> , index: true } ,
                {path: ':shopid' , element: <EventMainPage />} ,
            ]
        },
        {
            path: 'games' ,
            children: [
                {element: <GamesMainPage /> , index: true } ,
                {path: ':shopid/:gameid/leaderboard' , element: <GamesMainPage />} ,
                {path: ':shopid/:gameid/reflink' , element: <GamesRefPage />} ,
            ]
        },
        {
            path: 'quiz' ,
            children: [
                {element: <QuizDetailPage /> , index: true } ,
                {path: 'details' , element: <QuizDetailPage />}
            ]
        },
        {
            path: 'product',
            children: [
                { element: <ProductListPage />, index: true },
                { path: 'shop/:shopid', element: <ProductListPage /> },
                { path: 'shop/:shopid/ctg/:categ', element: <ProductListPage /> },
                { path: 'shop/:shopid/ctg/:categ/:pricing', element: <ProductListPage /> },
                { path: 'list', element: <ProductListPage /> },
                { path: ':shopid/:id', element: <ProductDetailsPage /> },
                { path: ':shopid/fastorder/:id', element: <ProductFastOrderPage /> },
                { path: 'article/:shopid/:id', element: <ProductArticleDetailPage /> },
                // { path: ':id', element: <ProductDetailsPage /> },
                // { path: 'checkout/:shopid', element: <ProductCheckoutPage /> },
                { path: 'checkout', element: <ProductCheckoutPage /> },
                { path: 'checkout/:shopid/fast/:id', element: <ProductCheckoutPage /> },
                { path: 'checkout/:shopid', element: <ProductCheckoutPage /> },
            ],
        },

    ],
  },
];
