import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import {useEffect, useMemo, useCallback, useState} from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
import { paths } from 'src/routes/paths';
import {useParams, useRouter} from 'src/routes/hooks';
// _mock
import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
//
import {isArray} from "lodash";
import { CheckoutContext } from './checkout-context';
import {useDispatch, useSelector} from "../../../redux/store";
import {getShopPromotions} from "../../../redux/slices/promotions";
import {useAuthContext} from "../../../auth/hooks";


// ----------------------------------------------------------------------

const STORAGE_KEY = 'checkout';

const initialState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  // shipping: 0,
  billing: null,
  totalItems: 0,
  shopid: null ,
  points: 0 ,
  usePoints: false ,
};

export function CheckoutProvider({ children  }) {
  const router = useRouter();

  const { user } = useAuthContext();
  const userBalance = user?.balance || 0
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const [quantityDiscount , setQuantityDiscount ] = useState({});

  // redux получить скидки promotions
    const dispatch = useDispatch();
    const { curShopId } = useSelector(( sliceState) => sliceState.startform)
    const { promotions } = useSelector(( sliceState) => sliceState.promotions)

    useEffect(() => {
        if(curShopId){
            dispatch(getShopPromotions(curShopId))
        }
    }, [curShopId, dispatch]);

    // useEffect(() => {
    //     if(isArray(promotions) && !isArray(quantityDiscount)){
    //         const qd = promotions.find(el => el.type === 'quantity discount');
    //         setQuantityDiscount(qd?.data)
    //     }
    //     const totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
    //     console.log("totalItems" , totalItems);
    //     let curDiscount = 0;
    //     if(isArray(quantityDiscount)){
    //         const arrQuantity = quantityDiscount?.map(el => el?.quantity)
    //         const qMax = arrQuantity.reduce((max, currentValue) => Math.max(max, currentValue), -Infinity); // максимальное количество товаров в настрйоках
    //         const countItems = totalItems >= qMax ? qMax : totalItems;
    //         curDiscount = quantityDiscount.find(el => el.quantity === countItems)?.discount
    //         update('discount', curDiscount)
    //     }
    //
    //
    // }, [promotions, quantityDiscount, state.items, update]);

    const onGetCart = useCallback(() => {

      const totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
      const subTotal = state.items.reduce((total, item) => total + item.quantity * item.price, 0);

      if(isArray(promotions) && !isArray(quantityDiscount)){
        const qd = promotions.find(el => el.type === 'quantity discount');
        setQuantityDiscount(qd?.data)
      }
      let curDiscount = 0;
      if(isArray(quantityDiscount)){
        const arrQuantity = quantityDiscount?.map(el => el?.quantity)
        const qMax = arrQuantity.reduce((max, currentValue) => Math.max(max, currentValue), -Infinity); // максимальное количество товаров в настрйоках
        const countItems = totalItems >= qMax ? qMax : totalItems;
        curDiscount = quantityDiscount.find(el => el.quantity === countItems)?.discount

        // расчитать из процентов
        const numDiscount = curDiscount ? subTotal / 100 * curDiscount : 0;
        update('discount', numDiscount)
      }

      if(state.usePoints){
          const pd = promotions.find(el => el.type === 'payments points');
          const maxDiscount = pd?.data?.max_discount || 0
          // cколько можно максимально использовано для скидки от суммы заказа
          let availableSum = Math.floor((state.subTotal/100)*maxDiscount)
          // сравним с текущим балансом баллов, если на балансе больше доступной сумму
          availableSum = userBalance <= availableSum ? userBalance : availableSum
          update('points' , availableSum)
      }else{
          update('points' , 0)
      }

    // const subTotal = state.items.reduce((total, item) => total + item.quantity * item.price, 0);
   // const newItems = state.items.map(el => ({...el , price: el.price - (el.price/100*curDiscount) }))

      // update('items', newItems);
      // const subTotal = newItems.reduce((total, item) => total + item.quantity * item.price, 0);

    update('subTotal', subTotal);
    update('totalItems', totalItems);
    update('billing', state.activeStep === 1 ? null : state.billing);
    // update('discount', state.items.length ? state.discount : 0);
    // update('shipping', state.items.length ? state.shipping : 0);
    // update('total', state.subTotal - state.discount + state.shipping);
    // update('points', state.items.length ? state.points : 0);

    update('total', state.subTotal - state.discount - state.points );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.items,
    state.activeStep,
    state.billing,
    state.discount,
    state.points,
        state.usePoints,
    // state.shipping,
    state.subTotal,
  ]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const onAddToCart = useCallback(
    (newItem) => {
        // console.log("NEW ITEM" , newItem);
      const updatedItems = state.items.map((item) => {
        if (item.id === newItem.id) {
          return {
            ...item,
            // colors: uniq([...item.colors, ...newItem.colors]),
            quantity: item.quantity + 1,
          };
        }
        return item;
      });

      if (!updatedItems.some((item) => item.id === newItem.id)) {
        updatedItems.push(newItem);
      }

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDeleteCart = useCallback(
    (itemId) => {
      const updatedItems = state.items.filter((item) => item.id !== itemId);

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onBackStep = useCallback(() => {
        update('activeStep', state.activeStep - 1);
  }, [update, state.activeStep]);

  const onNextStep = useCallback(() => {
    update('activeStep', state.activeStep + 1);
  }, [update, state.activeStep]);

  const onGotoStep = useCallback(
    (step) => {
      update('activeStep', step);
    },
    [update]
  );

  const onIncreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = state.items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDecreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = state.items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onCreateBilling = useCallback(
    (address) => {
      update('billing', address);

      onNextStep();
    },
    [onNextStep, update]
  );

  const onApplyDiscount = useCallback(
    (discount) => {
      update('discount', discount);
    },
    [update]
  );

  const onApplyPoints = useCallback(
        (points) => {
            console.log("update points" , points);
            update('points', points);
        },
        [update]
  );

    const onUsePoints = useCallback(
        (val) => {
            update('usePoints', val);
        },
        [update]
    );

  const onShopId = useCallback(
    (shopid) => {
      // console.log("UPDATE" , update);
      update('shopid', shopid);
    },
    [update]
  );

  const onApplyShipping = useCallback(
    (shipping) => {
      update('shipping', shipping);
    },
    [update]
  );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback((shopid) => {
    if (completed) {
      reset();
      router.replace(paths.product.shop(shopid));
    }
  }, [completed, reset, router]);

  const onResetWithoutReplace = useCallback(() => {
      if (completed) {
          reset();
      }
  } , [completed , reset])

  const onClear = useCallback(() => {
        reset();
  } , [reset])

  const onReplace = useCallback((href) => {
      router.replace(href);
  } , [router])

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onCreateBilling,
      onApplyDiscount,
      onApplyShipping,
      onApplyPoints ,
        onUsePoints ,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
        onReplace ,
        onResetWithoutReplace ,
        onClear ,
        //
      onShopId ,

    }),
    [
      completed,
      onAddToCart,
      onApplyDiscount,
      onApplyShipping,
      onApplyPoints,
        onUsePoints,
      onBackStep,
      onCreateBilling,
      onDecreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onIncreaseQuantity,
      onNextStep,
      onReset,
        onResetWithoutReplace ,
      onReplace ,
      onClear ,
      onShopId ,
      state,

    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}

CheckoutProvider.propTypes = {
  children: PropTypes.node,
};
