import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import {useRouter, useSearchParams} from 'src/routes/hooks';
//
import Box from "@mui/material/Box";
import { useAuthContext } from '../hooks';


const tg = window.Telegram.WebApp;
const tgData =  tg.initDataUnsafe;
const initData =  tg?.initData || '1234124reaedfadg3546qwer';
const probka = {
  id: "111111" ,
  first_name: "Евгений" ,
  last_name: "Кудряшов",
  username: "jonnnnster",
  photo_url: "/img",
  auth_date: "12.12.2022",
  hash: "1234124reaedfadg3546qwer"
}
// ----------------------------------------------------------------------

const loginPaths = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children , userType }) {
  const router = useRouter();
  const getParams = useSearchParams();

  const get_id = getParams.get('id');
  const get_first_name = getParams.get('first_name');
  const get_last_name = getParams.get('last_name');
  const get_username = getParams.get('username');
  const get_photo_url = getParams.get('photo_url');
  const get_auth_date = getParams.get('auth_date');
  const get_hash = getParams.get('hash');
  const tgWebAppStartParam = getParams.get('tgWebAppStartParam');
   //  console.log("tgWebAppStartParam" , tgWebAppStartParam);
  let AUTH_GET_HTTP = null;
  if(get_id && get_first_name
      && get_last_name
      && get_username
      && get_photo_url
      && get_auth_date
      && get_hash)
  {
    AUTH_GET_HTTP = {
      id: get_id ,
      first_name: get_first_name ,
      last_name: get_last_name,
      username: get_username,
      photo_url: get_photo_url,
      auth_date: get_auth_date,
      hash: get_hash
    }
  }

  const { authenticated, method , tgLogin } = useAuthContext();

  const [checked, setChecked] = useState(false);



  // ========= Логинимся ==========
  useEffect(() => {

    const authTg = async (data ) => {
      try {
        // console.log("APP" , data);
        await tgLogin(data );

      } catch (error) {
        // console.error(error);
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    const emptyTgObj = tgData.hasOwnProperty("user");

    const shopid = getShopIdFromPathname(window.location.pathname);

    let authdata = {};
    if(process.env.NODE_ENV !== 'production')authdata =  {...probka };
    if(AUTH_GET_HTTP)authdata = AUTH_GET_HTTP;
    if(emptyTgObj)authdata = {...tgData };

    // tgLogin(authdata);

      // if(!authenticated)
    if(process.env.NODE_ENV !== 'production') {
      authTg({ tg_response: authdata })
    } else if(userType === "subscriber"){
        authTg( { tg_response: initData , shop_id: shopid  , type: 'subscriber' } );
      }else{
        authTg( { tg_response: initData , shop_id: shopid  , type: 'user' } );
        // authTg( { tg_response: authdata , shop_id: shopid , initData } );

    }



  } , [AUTH_GET_HTTP, authenticated, tgLogin, userType]);
  // ==============================

  const check = useCallback(() => {
    console.log("authenticated check" , authenticated);
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = loginPaths[method];
      const tgstart = tgWebAppStartParam ? `?tgWebAppStartParam=${tgWebAppStartParam}` : '';
      const href = `${loginPath}?${searchParams}${tgstart}`;

      router.replace(href);

    } else {
      setChecked(true);
      console.log("set checked set true" );
    }
  }, [authenticated, method, router, tgWebAppStartParam]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!check) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  userType: PropTypes.string ,
};

function getShopIdFromPathname(strPathname){
    const regx = /(\/|%2F)(lp|shop)(\/|%2F)([\w\d]+)?/gm
    // /\/lp\/([\w\d]+)/gm
    // console.log("strPathname" , strPathname );
    const arrFindStrShop = strPathname ? [...strPathname.matchAll(regx)] : [] ;
    let shopid = ""
    if(arrFindStrShop.length > 0 ){
        if(arrFindStrShop[0]?.length >= 5){
            shopid = arrFindStrShop[0][4]
        }
    }
    return shopid

}
