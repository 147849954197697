import { createSlice } from '@reduxjs/toolkit';
// utils
import {isArray} from "lodash";
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    current_stories: [] ,
    stories: [] ,
    stories_del: false ,
};


const slice = createSlice({
    name: 'stories',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setStoriesSuccess(state, action) {
            state.isLoading = false;
            state.stories =  action.payload ;
        },
        setCurrentStories(state, action) {
            state.isLoading = false;
            state.current_stories =  action.payload ;
        },

        successDeleteItem(state , action){
            state.stories_del = true;
        } ,

        resetDeleteItemStatus(state , action){
            state.stories_del = false;
        } ,

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}

export function getStories(shopid ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/stories/${shopid}`);
            console.log("response Stories" , response);
            const resp = response.data.data;
            const {success} = response.data;

            if(response.data?.success){
                dispatch(slice.actions.setStoriesSuccess(resp));
            }else{
                dispatch(slice.actions.hasError(resp.error))
            }
            // console.log("group_id" , group_id);

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function getStoriesById(shopid , storiesID , tg_id=null ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/stories/${shopid}/${storiesID}?tg_id=${tg_id}`);
            console.log("response" , response);
            const resp = response.data.data;

            if(response.data?.success){
                dispatch(slice.actions.setCurrentStories(resp));
            }else{
                dispatch(slice.actions.hasError(resp.error))
            }

            // console.log("group_id" , group_id);


        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

// ===== ответы на форму юзера ====


export function delAnswersById(shopid , formid , ids){
    // https://api.notibot.ru/{shop_id}/{form_id}/fillings/delete
    console.log("IDS" , ids);
    const arrIds = isArray(ids) ? ids : [] ;
    const user_ids = {user_ids: arrIds}
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        dispatch(slice.actions.resetDeleteItemStatus());
        try {
            const response = await axios.post(`/forms/${shopid}/${formid}/fillings/delete` , user_ids);
            // console.log("delete" , response);
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.successDeleteItem());

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setShopID(shop));
}



