
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import usercard from "./usercard";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  leaderboard: [],
  gameuser: null ,
};

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.leaderboard = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LEADERBOARD
    getLeaderboardSuccess(state, action) {
      state.isLoading = false;
      state.leaderboard = action.payload;
    },

    // GET LEADERBOARD
    getGameuserSuccess(state, action) {
      state.isLoading = false;
      state.gameuser = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}

export function addUserInGame( shopid ,gameid, userInfo) {
 // console.log("user info" , userInfo);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(`/statistic/products/${shopid}`);
      const response = await axios.post(`/games/${shopid}/${gameid}/users` , {user_id: `${userInfo?.id}` , display_name: `${userInfo?.first_name} ${userInfo?.last_name}`});
      // console.log("Add game" , response);
      dispatch(slice.actions.getGameuserSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLeaderboard(shopid, gameid, userid) {

    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        // const response = await axios.get(`/statistic/products/${shopid}`);
        const response = await axios.get(`/games/${shopid}/${gameid}/${userid}`);


        dispatch(slice.actions.getLeaderboardSuccess(response.data.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
