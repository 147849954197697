// utils
import { paramCase } from 'src/utils/change-case'
import { _id } from 'src/_mock/assets'

// ----------------------------------------------------------------------

const MOCK_ID = _id[1]
// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
}

// ----------------------------------------------------------------------

export const paths = {
  badtg: {
    root: `/404.js`,
  },
  shop: {
    root: (shopid) => `/shop/${shopid}`,
  },
  quiz: {
    root: `/quiz/details`,
  },
  // mainapp:{
  //   root: `/mainapp`
  // },
  pays: {
    root: `/pays`,
    agreement: `/pays/agreement`,
    policy: `/pays/policy`,
  },
  lp: {
    root: (shopid) => `/lp/${shopid}`,
  },
  event: {
    root: (shopid) => `/event/${shopid}`,
  },
  games: {
    root: (shopid, gameid) => `/games/${shopid}/${gameid}/leaderboard`,
    reflink: (shopid, gameid) => `/games/${shopid}/${gameid}/reflink`,
    analytics: (shopid, gameid) => `/games/${shopid}/${gameid}/analytics`,
    fortune: (shopid) => `/games/${shopid}/fortune`,
  },


  product: {
    root: `/product/list`,
    checkout:  `/product/checkout`,
    order: (shopid)  => `/product/checkout/${shopid}`,
    fastorder: (shopid, id) => `/product/${shopid}/fastorder/${id}` ,
    list: (shopid) => `/product/${shopid}`,
    shop: (id) => `/product/shop/${id}`,
    details: (shopid, id) => `/product/${shopid}/${id}`,
    article: (shopid, id) => `/product/article/${shopid}/${id}`,
    category: (id, categ) => `/product/shop/${id}/ctg/${categ}`,
    categprice: (id, categ, price) => `/product/shop/${id}/ctg/${categ}/${price}`,
    // details: (shopid , id ) => `/product/${shopid}/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    shop: (sid) => `${ROOTS.DASHBOARD}/shop/${sid}`,
    one: `${ROOTS.DASHBOARD}/one`,
    notifylist: `${ROOTS.DASHBOARD}/ntflist`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    stories:{
      root: (shopid) => `${ROOTS.DASHBOARD}/stories/${shopid}/list`,
    },
    usercard: {
      root: (sid) => `${ROOTS.DASHBOARD}/usercard/${sid}`,
      addblock: (sid , type , id ) => `${ROOTS.DASHBOARD}/usercard/${sid}/addblock/${type}/${id}`,
      settings: (sid) => `${ROOTS.DASHBOARD}/usercard/${sid}/settings/`,
    },
    general: {
      shop: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/shop`,
      usercard: (sid) => `${ROOTS.DASHBOARD}/general/usercard/${sid}`,
      usercardchoice: (sid) => `${ROOTS.DASHBOARD}/general/usercardchoice/${sid}`,
      settings: (sid) => `${ROOTS.DASHBOARD}/general/settings/${sid}`,
      articles: (sid) => `${ROOTS.DASHBOARD}/general/articles/${sid}`,
      tools: (sid) => `${ROOTS.DASHBOARD}/general/tools/${sid}`,
      qs: (sid) => `${ROOTS.DASHBOARD}/general/qs/${sid}`,
      chatbot: (sid) => `${ROOTS.DASHBOARD}/general/chatbot/${sid}`,
      goods: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/goods`,
      pages: (sid) => `${ROOTS.DASHBOARD}/general/articles/${sid}/list`,
      edit_good: (sid, id) => `${ROOTS.DASHBOARD}/general/${sid}/goods/${id}/edit`,
      edit_buy_message: (sid, id) => `${ROOTS.DASHBOARD}/general/${sid}/goods/${id}/bmsg`,
      group_lock: (sid, id) => `${ROOTS.DASHBOARD}/general/${sid}/goods/${id}/grouplock`,
      add_good: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/goods/add`,
      add_page: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/articles/add`,
      edit_page: (sid, id) => `${ROOTS.DASHBOARD}/general/${sid}/article/${id}/edit`,
      payment: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/payment`,
      paymentManual: (sid) => `${ROOTS.DASHBOARD}/general/paymentManual/${sid}`,
      set_agreement: (sid) => `${ROOTS.DASHBOARD}/general/settings/agreement/${sid}`,
      set_metrika: (sid) => `${ROOTS.DASHBOARD}/general/settings/metrika/${sid}`,
      promotions: {
        root: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/promotions`,
        type: (sid , type) => `${ROOTS.DASHBOARD}/general/${sid}/promotions/${type}`,
        incart: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/promotions/incart`,
        points: (sid) => `${ROOTS.DASHBOARD}/general/${sid}/promotions/points`,
      } ,
      paymentAuto: {
        list: (sid) => `${ROOTS.DASHBOARD}/general/paymentAuto/${sid}/list/`,
        type: (sid, type) => `${ROOTS.DASHBOARD}/general/paymentAuto/${sid}/${type}`,
      },
    },
    pages: {
      list: (sid) => `${ROOTS.DASHBOARD}/pages/${sid}/list/`,
      detail: (sid, id) => `${ROOTS.DASHBOARD}/pages/${sid}/detail/${id}`,
      create: (sid) => `${ROOTS.DASHBOARD}/pages/${sid}/create`,
    },
    orders: {
      list: (sid) => `${ROOTS.DASHBOARD}/orders/${sid}/list/`,
    },
    analytics: {
      list: (sid) => `${ROOTS.DASHBOARD}/analytics/${sid}/list/`,
      type: (sid, type) => `${ROOTS.DASHBOARD}/analytics/${sid}/${type}`,
      // detail: (sid , id) => `${ROOTS.DASHBOARD}/analitycs/${sid}/detail/${id}` ,
    },
    notify: {
      root: `${ROOTS.DASHBOARD}/notify`,
      list: (sid) => `${ROOTS.DASHBOARD}/notify/${sid}/list/`,
      new: (sid) => `${ROOTS.DASHBOARD}/notify/${sid}/new`,
      auto: (sid) => `${ROOTS.DASHBOARD}/notify/${sid}/auto`,
      article: (shopid) => `${ROOTS.DASHBOARD}/notify/auto/element/${shopid}/article`,
      product: (shopid) => `${ROOTS.DASHBOARD}/notify/auto/element/${shopid}/product`,
      form: (shopid) => `${ROOTS.DASHBOARD}/notify/auto/element/${shopid}/form`,
      type: (type) => `${ROOTS.DASHBOARD}/notify/auto/${type}`,
      open_trigger: (type, elementid, sid) => `${ROOTS.DASHBOARD}/notify/auto/${sid}/${type}/${elementid}`,
      view: (id) => `/notify/${id}`,
      edit: (sid , id) => `${ROOTS.DASHBOARD}/notify/${sid}/${id}/edit`,
      create: (type) => `${ROOTS.DASHBOARD}/notify/${type}/create`,
      crt: (type, sid, elementid) => `${ROOTS.DASHBOARD}/notify/${sid}/${type}/${elementid}/create`,
      shop: (sid) => `${ROOTS.DASHBOARD}/notify/shop/${sid}`,

    },
    subscribers: {
      root: `${ROOTS.DASHBOARD}/subscribers`,
      list: (sid) => `${ROOTS.DASHBOARD}/subscribers/list/${sid}`,
      // new: `${ROOTS.DASHBOARD}/notify/new`,
      // view: (id) => `/notify/${id}` ,
      // edit: (id) => `${ROOTS.DASHBOARD}/notify/${id}/edit`,
      // shop: (sid) => `${ROOTS.DASHBOARD}/notify/shop/${sid}`,
    },
    referals: {
      root: `${ROOTS.DASHBOARD}/referals`,
      list: (sid) => `${ROOTS.DASHBOARD}/referals/list/${sid}`,

    },
    forms: {
      root: (sid) => `${ROOTS.DASHBOARD}/forms/list/${sid}`,
      detail: (sid, fid) => `${ROOTS.DASHBOARD}/forms/detail/${sid}/${fid}`,

    },
    integrations: {
      root: (shopid) => `${ROOTS.DASHBOARD}/integrations/${shopid}`,
      gk: (shopid) => `${ROOTS.DASHBOARD}/integrations/${shopid}/gk`,
      amo: (shopid) => `${ROOTS.DASHBOARD}/integrations/${shopid}/amo`,

    },
    settings: {
      root: (shopid) => `${ROOTS.DASHBOARD}/settings/${shopid}`,
      shop: (shopid) => `${ROOTS.DASHBOARD}/settings/shop/${shopid}`,
    },
    pay: {
      root: `${ROOTS.DASHBOARD}/pays`,
      pricing: (sid) => `${ROOTS.DASHBOARD}/pays/${sid}`,
    },
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
}
