import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';


// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    currentShop: '' ,
    notify: {
        list: [],
        currentNotify: [] ,
        del: false ,
        test : {wait: false , sending: false , nid: null} ,
    }
};

const slice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getNotifyListSuccess(state, action){
            state.isLoading = false;
            state.notify.list = action.payload;
        } ,

        getNotifyItemSuccess(state , action){
            state.isLoading = true;
            state.notify.currentNotify = action.payload;
        } ,

        getNotifyCurrentShop(state , action){
            state.isLoading = false;
            state.currentShop = action.payload;
        } ,

        successDeleteItem(state , action){
            state.notify.del = true;
        } ,

        resetDeleteItemStatus(state , action){
            state.notify.del = false;
        } ,

        startSendTest(state){
            state.notify.test.wait = true;
            state.notify.test.sending = false;
        },

        stopSendTest(state){
            state.notify.test.nid = null;
            // state.notify.test.sending = false;
        },

        setNidTest(state , action){
            state.notify.test.nid = action.payload;
        },

        successSendTest(state){
            state.notify.test.wait = false;
            state.notify.test.sending = true;

        },

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------- CREATE FORM -----------------

export function setCurrentShopId(sid){
    return (dispatch) => {
        dispatch(slice.actions.getNotifyCurrentShop(sid));
    }
}

export function getNotifyById(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get(`/notifications/${id}`);

            // console.log("axios response NotifyById" , response);
            dispatch(slice.actions.getNotifyItemSuccess(response.data));
            // dispatch(slice.actions.setNidTest(null));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getNotifyList(shop_id) {
    const sid = shop_id === 0 ? '' : shop_id;
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/notifications/${sid}`);
            // console.log(`axios Notify response >>> ${sid} ` , response);
            dispatch(slice.actions.getNotifyListSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function delts(status){
    return async (dispatch) => {
        if(status){
            dispatch(slice.actions.successDeleteItem());
        }else{
            dispatch(slice.actions.resetDeleteItemStatus());
        }
    }
}
export function delNotifyById(id){
    // console.log("DEL ID" , id);
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        dispatch(slice.actions.resetDeleteItemStatus());
        try {
            const response = await axios.delete(`/notifications/${id}`);
            // console.log("delete" , response);
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.successDeleteItem());

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function resetDelStatus(){
    return async (dispatch) => {
        dispatch(slice.actions.resetDeleteItemStatus());
    }
}
export function delNotifyByIds(ids=[]){
    console.log("DEL ID" , ids);
    return async (dispatch) => {
        dispatch(slice.actions.resetDeleteItemStatus());
        //  dispatch(slice.actions.startLoading());
        try {
            const notifyIds = {data: {notification_ids: ids}};

            const response = await axios.delete(`/notifications/` , notifyIds );
            if(response?.data?.success){
                console.log("response" , response);
                dispatch(slice.actions.successDeleteItem());
            }else{
                console.log("response error" , response);
                dispatch(slice.actions.hasError(response?.data?.error));
                alert(response?.data?.error)
            }

            //  dispatch(slice.actions.stopLoading());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ---- тестовая отправка ------

export function sendTestNotify(nid) {
    return async (dispatch) => {
        dispatch(slice.actions.startSendTest());
        dispatch(slice.actions.setNidTest(nid));
        try {
            const response = await axios.get(`/notification_test/${nid}`);
            console.log("axios response " , response.data.status);
            dispatch(slice.actions.successSendTest());

            // dispatch(slice.actions.stopSendTest());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopSendTest());
        }
    };
}





