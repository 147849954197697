import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';


// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    submit: false ,
    promotions: [] ,
};


const slice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setPromotions(state, action) {
            state.isLoading = false;
            state.promotions = action.payload;
        },
        setSubmit(state, action) {
            state.isLoading = false;
            state.submit = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {setPromotions, setSubmit} = slice.actions;

// ----------------------------------------------------------------------

export function getPayments(id , tg_id = null){
    // console.log("ID" , id);
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/payments/${id}?tg_id=${tg_id}`);

            // console.log(`axios response ${id}` , response.data);
            if(response.data.success === false) {
                dispatch(slice.actions.hasError(response.data.data));
            }else{

                dispatch(slice.actions.setPaymentsShop(response.data.data));
            }



        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getShopPromotions(shopid){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/promotions/${shopid}`);

            // console.log(`axios Promotions ` , response.data);
           if(response.data.success === false) {
                dispatch(slice.actions.hasError(response.data.data));
           }else{
               dispatch(slice.actions.setPromotions(response.data.data));
               // dispatch(slice.actions.setSubmit(true));
           }
        } catch (error) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function addPromotion(shopid , data){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/promotions/${shopid}` , data);

            // console.log(`axios Promotions ` , response.data);
            // // if(response.data.success === false) {
            //      dispatch(slice.actions.hasError(response.data.data));
            // // }

            dispatch(slice.actions.setPromotions(response.data.data));

        } catch (error) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(error));
        }
    }
}





