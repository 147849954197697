import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    admin: false ,
    fromDashboard: null ,
    fromShopid: null ,
    product : null ,
    usercard : {addblock: null , add_btn_click_from: null , change_block_id: null , open_dialog_settings_block: false}
};


const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setFromDashbaord(state, action) {
            const {from , shopid} = action.payload;
            state.isLoading = false;
            state.fromDashboard =  from ;
            state.fromShopid =  shopid ;
        },

        // GET PRODUCTS
        getProductSuccess(state, action) {
            state.isLoading = false;
            state.product = action.payload;
        },

        // set addblock
        setSuccessAddItemBlock(state , action) {
            state.isLoading = false;
            state.usercard.addblock = action.payload;
        } ,
        // открыт диалок изменения блоков
        setOpenDialogSettingsBlock(state , action) {
            state.isLoading = false;
            state.usercard.open_dialog_settings_block = action.payload;
        } ,

        // кнопка добавить страницу нажата из другого компонента
        setSuccessBtnClickFrom(state , action) {
            state.isLoading = false;
            state.usercard.add_btn_click_from = action.payload;
        } ,

        // выбран блок для редактировании визитки
        setEditBlockId(state , action) {
            state.isLoading = false;
            state.usercard.change_block_id = action.payload;
        }

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setFromDashboard(val){
    return (dispatch) => {
        dispatch(slice.actions.setFromDashbaord(val));
    }
}

export function setAddItemToBlock(val){
    return (dispatch) => {
        dispatch(slice.actions.setSuccessAddItemBlock(val));
    }
}

export function setOpenDialogSettingsBlock(val){
    return (dispatch) => {
        dispatch(slice.actions.setOpenDialogSettingsBlock(val));
    }
}

export function setClickBtnAddPageFrom(val){
    return (dispatch) => {
        dispatch(slice.actions.setSuccessBtnClickFrom(val));
    }
}

export function setEditBlockId(val){
    return (dispatch) => {
        dispatch(slice.actions.setEditBlockId(val));
    }
}

export function getAdminProduct(shopid , id ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/products/admin/${shopid}/${id}?parsing=0`);
            // console.log("RESPP" , response);
            dispatch(slice.actions.getProductSuccess(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}




