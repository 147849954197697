// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// sections
import { CheckoutProvider } from 'src/sections/checkout/context';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';


// redux
import {useParams} from "react-router-dom";
import { store, persistor } from './redux/store';
import ErrorBoundry from "./sections/errorboundry/error-boundry";
import {useLocales} from "./locales";
import LangSetup from "./components/lang/lang-setup";

// // ----------------------------------------------------------------------
// const tg = window.Telegram?.WebApp;
// const userData = tg?.initDataUnsafe  ;
// const tg_id = userData?.user ?  userData.user.id : null;
export default function App() {
  // const charAt = `
  //
  // ░░░    ░░░
  // ▒▒▒▒    ▒▒
  // ▒▒ ▒▒   ▒▒
  // ▓▓  ▓▓  ▓▓
  // ██    ████
  //
  // `;
  //
  // console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();
  const {shopid} = useParams();
   // const locales = useLocales();

    // ----- LANGUAGE -----

    // if(userData?.user?.language_code){
    // locales.onChangeLang('en');
    // }

  return (
    <AuthProvider params={{shopid}}>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>

      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
          tgUID: 174960070 ,
        }}
      >

        <ThemeProvider>
          <MotionLazy>
              <SnackbarProvider>
                  <ErrorBoundry>
                  <CheckoutProvider shopid={shopid}>
                    <SettingsDrawer />
                        <ProgressBar />
                            <AuthConsumer>
                                <Router />
                            </AuthConsumer>
                  </CheckoutProvider>
                  </ErrorBoundry>
              </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
            </PersistGate>
        </ReduxProvider>
    </AuthProvider>
  );
}
