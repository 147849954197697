import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes , ShopPage , MainAppPage } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import CompactLayout from "../../layouts/compact";
import MainLayout from '../../layouts/main';
import SimpleLayout from '../../layouts/simple';



// ----------------------------------------------------------------------

export default function Router() {


  return useRoutes([
    {
      path: '/',
      // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      element: (
          <SimpleLayout>
            <ShopPage />
          </SimpleLayout>
      ),
    },
    { path: '/mainapp', element: (
          <SimpleLayout>
            <MainAppPage />
          </SimpleLayout>
      ) } ,

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
