
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    orderLoading: false ,
    error: null,
    // article: [],
    orders: [],
    order: [] ,
    // forms: [],
};

const slice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startOrderLoading(state) {
            state.orderLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },


        setOrdersSuccess(state, action) {
            state.isLoading = false;
            state.orders = action.payload;
        },

        setOrderSuccess(state, action) {
            state.orderLoading = false;
            state.order = action.payload;
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getOrders(shopid) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // const response = await axios.get(`/statistic/products/${shopid}`);
            const response = await axios.get(`/orders/${shopid}`);

            console.log("Orders" , response);
            if(!response?.data?.success){
                alert(response?.data?.data)
                throw new Error(`${response?.data?.data}`);
            }else{
                dispatch(slice.actions.setOrdersSuccess(response.data.data));
            }

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrderById(shopid , id) {

    return async (dispatch) => {
        dispatch(slice.actions.startOrderLoading());
        try {
            // const response = await axios.get(`/statistic/products/${shopid}`);
            const response = await axios.get(`/orders/${shopid}/${id}`);

            if(!response?.data?.success){
                alert(response?.data?.data)
                throw new Error(`${response?.data?.data}`);
            }else{
                dispatch(slice.actions.setOrderSuccess(response.data.data));
            }

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
